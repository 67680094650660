import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import data from "../../roadmap/Q4-2021-details";
import Page from "../../components/Page";
import InlineNav from "../../components/InlineNav";
import FeaturesAccordion from "../../components/FeaturesAccordion";
import getPrevNextRoadmaps from "../../utils/getPrevNextRoadmaps";
import WithSideNav from "../../components/WithSideNav";
import Section from "../../components/Section";

const roadmapId = "Q4-2021";

function RoadmapPage({ location }) {
  const sideNavProps = [
    {
      title: "Collaborate",
      link: "/collaborate"
    },
    {
      title: "News & Updates",
      link: "/news"
    },
    {
      title: "Roadmap",
      link: "/roadmap"
    }
  ];

  const { prev, next } = getPrevNextRoadmaps(roadmapId);
  return (
    <Page>
      <Section>
        <WithSideNav sideNavProps={sideNavProps} title="Roadmap">
          <p className="mt-8 mb-8 ">
            We are excited to share our plans with the community! Below you may
            find an outline of our ongoing projects and major components
            scheduled to be included in upcoming feature versions. We would love
            to hear your feedback on our roadmap and welcome you to join the
            conversation on our
            <span className="text-blue-100">
              <a
                href="https://community.ohif.org/"
                target="_blank"
                rel="noreferrer"
              >
                {" community forum."}
              </a>
            </span>
          </p>
          <div className="flex gap-2 text-white">
            <InlineNav
              prev={prev}
              next={next}
              current={"Q4 2021 Update"}
              base={"roadmap"}
            />
          </div>
          <div className="mb-8 mt-2">
            <StaticImage
              src={"../../images/Roadmap-2021-Q4-to-2022-Q3.png"}
              alt="roadmap"
            />
          </div>
          <div className="mb-20 mt-15 text-white">
            <h3 className="phone-size:h3-heading-ps lg:h3-heading-lg">
              Details
            </h3>
            <FeaturesAccordion
              data={data}
              location={location}
              basePath={`roadmap/${roadmapId}`}
            />
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default RoadmapPage;
